<template>
  <product title="Amazfit GTS 4"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1199"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://item.jd.com/100037094239.html"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'gts4',
  components: {
    Product
  },
  data () {
    return {
      slogan: '血压健康研究 | 高精度运动心率 | 精致轻薄设计 | 六星双频精准定位 | 健身房力量训练',
      colors: [
        '静谧黑',
        '水韵白',
        '落日金',
        '花漾粉'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/gts4/swiper/1-1.png',
          CDN_URL + '/images/product/gts4/swiper/1-2.png',
          CDN_URL + '/images/product/gts4/swiper/1-3.png',
          CDN_URL + '/images/product/gts4/swiper/1-4.png'
        ],
        [
          CDN_URL + '/images/product/gts4/swiper/2-1.png',
          CDN_URL + '/images/product/gts4/swiper/2-2.png',
          CDN_URL + '/images/product/gts4/swiper/2-3.png',
          CDN_URL + '/images/product/gts4/swiper/2-4.png'
        ],
        [
          CDN_URL + '/images/product/gts4/swiper/3-1.png',
          CDN_URL + '/images/product/gts4/swiper/3-2.png',
          CDN_URL + '/images/product/gts4/swiper/3-3.png',
          CDN_URL + '/images/product/gts4/swiper/3-4.png'
        ],
        [
          CDN_URL + '/images/product/gts4/swiper/4-1.png',
          CDN_URL + '/images/product/gts4/swiper/4-2.png',
          CDN_URL + '/images/product/gts4/swiper/4-3.png',
          CDN_URL + '/images/product/gts4/swiper/4-4.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/gts4/detail/1.jpg',
        CDN_URL + '/images/product/gts4/detail/2.jpg',
        CDN_URL + '/images/product/gts4/detail/3.jpg',
        CDN_URL + '/images/product/gts4/detail/4.jpg',
        CDN_URL + '/images/product/gts4/detail/5.jpg',
        CDN_URL + '/images/product/gts4/detail/6.jpg',
        CDN_URL + '/images/product/gts4/detail/7.jpg',
        CDN_URL + '/images/product/gts4/detail/8.jpg',
        CDN_URL + '/images/product/gts4/detail/9.jpg',
        CDN_URL + '/images/product/gts4/detail/10.jpg',
        CDN_URL + '/images/product/gts4/detail/11.jpg',
        CDN_URL + '/images/product/gts4/detail/12.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/gts4/detail/13.jpg',
        CDN_URL + '/images/product/gts4/detail/14.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
